// Company.js
import React from 'react';
import  Navbar  from './Navbar';

const companies = [
  {
    id: 1,
    work: 'IT',
    name: "Tech Solutions",
    about: "Innovative tech solutions for the modern world.",
    location: "San Francisco, CA"
  },
  {
    id: 2,
    work: 'NonIT',
    name: "HealthCare Inc.",
    about: "Providing healthcare solutions globally.",
    location: "New York, NY"
  },
  {
    id: 3,
    work: 'IT',
    name: "EduWorld",
    about: "Empowering education through technology.",
    location: "Boston, MA"
  },
  // Add more companies as needed
];

const Company = ({ isLoggedIn ,userRole, handleLogout, username }) => {
  return (

    <>

    <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          />
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
      {/* NonIT Companies */}
      <div style={{ flex: 1, marginRight: '10px' }}>
        <h2 style={{ textAlign: 'center', color: '#333' }}>NON IT JOB</h2>
        {companies
          .filter(company => company.work === 'NonIT')
          .map(company => (
            <div
              key={company.id}
              style={{
                maxWidth: '600px',
                marginBottom: '16px',
                padding: '16px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                color: '#007bff'
              }}>
                📍
                <span style={{ marginLeft: '4px' }}>{company.location}</span>
              </div>
              <h3 style={{ margin: '0', fontSize: '18px', color: '#333' }}>{company.name}</h3>
              <p style={{ margin: '8px 0', color: '#555', fontSize: '14px', lineHeight: '1.4' }}>
                {company.about}
              </p>
              <button
                style={{
                  position: 'absolute',
                  bottom: '16px',
                  right: '16px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                Allocated College
              </button>
            </div>
          ))}
      </div>

      {/* IT Companies */}
      <div style={{ flex: 1, marginLeft: '10px' }}>
        <h2 style={{ textAlign: 'center', color: '#333' }}>IT JOB</h2>
        {companies
          .filter(company => company.work === 'IT')
          .map(company => (
            <div
              key={company.id}
              style={{
                maxWidth: '600px',
                marginBottom: '16px',
                padding: '16px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div style={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                color: '#007bff'
              }}>
                📍
                <span style={{ marginLeft: '4px' }}>{company.location}</span>
              </div>
              <h3 style={{ margin: '0', fontSize: '18px', color: '#333' }}>{company.name}</h3>
              <p style={{ margin: '8px 0', color: '#555', fontSize: '14px', lineHeight: '1.4' }}>
                {company.about}
              </p>
              <button
                style={{
                  position: 'absolute',
                  bottom: '16px',
                  right: '16px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                Allocated College
              </button>
            </div>
          ))}
      </div>
    </div>
    </>
  );
};

export default Company;
