import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Col, Dropdown, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import MonacoEditor from "@monaco-editor/react";
import './QuestionPage.css';
import  Navbar  from './Navbar';

const QuestionPage = ({ isLoggedIn ,userRole, handleLogout, username }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sourceCode, setSourceCode] = useState('');
    const [stdin, setStdin] = useState('');
    const [expectedOutput, setExpectedOutput] = useState('');
    const [result, setResult] = useState('');
    const [error, setError] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [passedCases, setPassedCases] = useState(0);
    const [totalCases, setTotalCases] = useState(0);
    const [isEditorLocked, setIsEditorLocked] = useState(false);
    const [output, setOutput] = useState('');
    const [showInputOutput, setShowInputOutput] = useState(false);
    const [showTestCases, setShowTestCases] = useState(false);
    const [isRunClicked, setIsRunClicked] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); 
    const [userId, setUserId] = useState(null); // State to hold user ID
    

    useEffect(() => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
    }, []);

    // Fetch user ID based on username
    useEffect(() => {
        const fetchUserId = async () => {
            const username = localStorage.getItem('username'); // Assuming the username is stored in localStorage
            try {
                const response = await axios.get('https://1c71-106-219-181-170.ngrok-free.app/quiz/users/', {
                    headers: {
                      Accept: 'application/json',
                      'ngrok-skip-browser-warning': '98547',
                    },
                });
                const user = response.data.find(user => user.username === username);
                if (user) {
                    setUserId(user.id);
                } else {
                    setError('User not found.');
                }
            } catch (err) {
                setError('Error fetching user data.');
            }
        };

        fetchUserId();
    }, []);

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await axios.get('https://1c71-106-219-181-170.ngrok-free.app/compiler/languages/', {
                    headers: {
                      Accept: 'application/json',
                      'ngrok-skip-browser-warning': '98547',
                    },
                });

                if (response.status === 200) {
                    if (Array.isArray(response.data)) {
                        setLanguages(response.data);
                    } else {
                        setError('Unexpected data format.');
                    }
                } else {
                    setError('Failed to fetch languages.');
                }
            } catch (err) {
                setError('Error fetching languages.');
            }
        };
    
        fetchLanguages();
    }, []);   

    const handleLanguageChange = (lang) => {
        setSelectedLanguage(lang);
        switch (lang) {
            case 'Java':
                setSourceCode(`import java.io.*;
import java.util.*;

public class Solution {

    public static void main(String[] args) {
        /* Enter your code here. */
    }
}`);
                break;
            case 'Python':
                setSourceCode('# Write your Python code here');
                break;
            case 'C++':
                setSourceCode(`#include <iostream>

int main() {
    // Write C++ code here
    std::cout << "Hello, World!";
    return 0;
}`);
                break;
            default:
                setSourceCode('');
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsRunClicked(true);

        const requestData = {
            source_code: sourceCode,
            language: selectedLanguage,
            expected_output: expectedOutput,
            question_id: location.state.question.id,
            user_id: userId,  // Use dynamic user ID
        };

        try {
            const response = await axios.post(
                'https://1c71-106-219-181-170.ngrok-free.app/compiler/run-test/',
                requestData
            );

            if (response.data.passed_cases !== undefined && response.data.total_cases !== undefined) {
                setPassedCases(response.data.passed_cases);
                setTotalCases(response.data.total_cases);
                setIsEditorLocked(true);
                setShowTestCases(true);
                setShowInputOutput(false);
            } else {
                setError('Unexpected response format.');
            }

            if (response.data.output === expectedOutput) {
                setResult('Answer correct');
            } else {
                setResult('Answer wrong');
            }
        } catch (err) {
            setError('Error submitting the answer. Please try again.');
        }
    };
    const handleCompile = async () => {
        const requestData = {
            source_code: sourceCode,
            language: selectedLanguage,
            stdin: stdin,
        };

        try {
            const compileResponse = await axios.post(
                'https://1c71-106-219-181-170.ngrok-free.app/compiler/compile/',
                requestData
            );

            if (compileResponse.data.error) {
                setOutput(compileResponse.data.error);
                setIsSubmitDisabled(true);
                return;
            }

            setOutput(compileResponse.data.output || 'Compiled successfully!');
            setIsSubmitDisabled(false);
        } catch (err) {
            setError('Error compiling the code. Please try again.');
            setIsSubmitDisabled(true);
        }
    };

    const toggleInputOutput = () => {
        setShowInputOutput((prev) => !prev);
        setShowTestCases(false);
    };

    return (

        <>

    <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          />
        <Container fluid className="question-page">
            <Row className="d-flex" style={{ height: '100%' }}>
                <Col md={3} className="description-column d-flex flex-column">
                    <h4><b>Description :</b></h4>
                    <p>{location.state.question.question}</p>
                </Col>

                <Col
                    md={showInputOutput || showTestCases ? 6 : 9}
                    className="code-column d-flex flex-column"
                >
                    <div className="code-header d-flex justify-content-between align-items-center">
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" name="lan">
                                {selectedLanguage || 'Select Language'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {languages.length > 0 ? (
                                    languages.map((langObj) => (
                                        <Dropdown.Item
                                            key={langObj.id}
                                            onClick={() => handleLanguageChange(langObj.language)}
                                        >
                                            {langObj.language}
                                        </Dropdown.Item>
                                    ))
                                ) : (
                                    <Dropdown.Item disabled>Loading languages...</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Button variant="success" onClick={handleSubmit}>
                            Run
                        </Button>
                        <Button variant="success" onClick={toggleInputOutput}>
                            Custom Input/Output
                        </Button>
                    </div>

                    <MonacoEditor
                        height="600px"
                        language={selectedLanguage ? selectedLanguage.toLowerCase() : 'plaintext'}
                        value={sourceCode}
                        onChange={(value) => setSourceCode(value)}
                        options={{
                            selectOnLineNumbers: true,
                            theme: 'vs-dark',
                            automaticLayout: true,
                            minimap: { enabled: false },
                            scrollbar: { verticalScrollbarSize: 0, horizontalScrollbarSize: 0 },
                            padding: { top: 0, bottom: 0 },
                        }}
                    />

                </Col>

                {showInputOutput && (
                    <Col md={3} className="input-output-column">
                        <Form.Group controlId="stdinInput" className="custom-input-box">
                            <Form.Label>Input</Form.Label>
                            <div className="input-with-button" style={{ position: 'relative' }}>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={stdin}
                                    onChange={(e) => setStdin(e.target.value)}
                                    className="custom-textarea"
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        border: '1px solid gray',
                                        borderRadius: '5px',
                                        resize: 'none',
                                        paddingRight: '70px',
                                    }}
                                />
                                <Button
                                    variant="success"
                                    onClick={handleCompile}
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        padding: '5px 10px',
                                        fontSize: '0.85rem',
                                        height: '30px',
                                        lineHeight: '1rem',
                                    }}
                                >
                                    Compile
                                </Button>
                            </div>
                        </Form.Group>

                        <Form.Group controlId="outputInput">
                            <Form.Label>Output</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={output}
                                readOnly
                                className="custom-textarea"
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    border: '1px solid gray',
                                    borderRadius: '5px',
                                    resize: 'none',
                                }}
                            />
                        </Form.Group>
                    </Col>
                )}
                {showTestCases && (
                    <Col md={3} className="input-output-column">
                        <Form.Group controlId="testCaseResults" className="custom-input-box">
                            <Form.Label>Test Case Results</Form.Label>
                            <div className="test-case-results">
                                <ul style={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
                                    {Array.from({ length: totalCases }, (_, index) => (
                                        <li key={index} style={{ margin: '0 10px' }}>
                                            {index < passedCases ? (
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'green',
                                                        color: 'white',
                                                        textAlign: 'center',
                                                        lineHeight: '30px',
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    ✓
                                                </span>
                                            ) : (
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        textAlign: 'center',
                                                        lineHeight: '30px',
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    ✕
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                <Button variant="success" href="/Dashboard">Submit</Button>
                            </div>
                        </Form.Group>
                    </Col>
                )}
            </Row>
        </Container>
        </>
    );
};

export default QuestionPage;