import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUp.css';

const SignUp = ({ setIsLoggedIn, setUsername }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    role: 'member', // Default role is capitalized
    college: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://1c71-106-219-181-170.ngrok-free.app/quiz/users/create/user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        console.error('Error details:', responseData); // Log specific error details from backend
        alert(responseData.message || 'Registration failed. Please try again.');
        throw new Error(responseData.message || 'Registration failed');
      }

      console.log('Registration successful:', responseData);
      alert('Registration successful!');

      // Store username in localStorage
      localStorage.setItem('username', formData.username);

      // Update the App state for logged-in user
      setIsLoggedIn(true);
      setUsername(formData.username);

      // Redirect to login page
      navigate('/LoginPage');
    } catch (error) {
      console.error('Error during registration:', error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleSubmit}>
        <h2>Register</h2>
        <div className="input-container">
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username"
            required
          />
        </div>
        <div className="input-container">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>
        <div className="input-container">
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
        </div>
        <div className="input-container">
          <input
            type="text"
            name="college"
            value={formData.college}
            onChange={handleChange}
            placeholder="College"
            required
          />
        </div>
        <div className="input-container">
          <select name="role" value={formData.role} onChange={handleChange}>
            <option value="member">Member</option>
            <option value="admin">Admin</option>
          </select>
        </div><br></br>
        <div className="button-container">
          <button type="submit" className="register-btn" disabled={isLoading}>
            {isLoading ? 'Registering...' : 'Register'}
          </button>
        </div>
        <div className="login-link">
          <span>Already have an account? </span>
          <button type="button" className="login-btn" onClick={() => navigate('/LoginPage')}>
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
