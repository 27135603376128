// LogoSection.js
import React from 'react';
import Info from './images/infosys-logo-jpeg.png';
import Capgemini from './images/Capgemini-Logo.png';
import Tcs from './images/tcs_logo_1200_020621101143.png';
import Google from './images/google2.0.0.1441125613.png';
import HP from './images/1200px-HP_logo_2012.svg.png';
import IBM from './images/416_ibm.png';
import Microsoft from './images/Microsoft logo.png';
import Amazon from './images/amazon logo.png';
import './LogoSection.css'; // Assuming you will create a CSS file for styling

const LogoSection = () => {
  return (
    <div className="logos-container">
      <div className="logos">
        <img src={Info} alt="Infosys" />
        <img src={Capgemini} alt="Capgemini" />
        <img src={Tcs} alt="TCS" />
        <img src={Google} alt="Google" />
        <img src={HP} alt="HP" />
        <img src={IBM} alt="IBM" />
        <img src={Microsoft} alt="Microsoft" />
        <img src={Amazon} alt="Amazon" />
      </div>
    </div>
    
  );
}

export default LogoSection;
