import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Card, ListGroup, Modal } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaClock, FaExclamationTriangle, FaStopwatch } from 'react-icons/fa'; // Import different icons
import  Navbar  from './Navbar';

const McqTestPage = ({ isLoggedIn, userRole, handleLogout, username }) => {
  const { state } = useLocation();
  const { subtype } = state || {};
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState({ attended: 0, correct: 0, wrong: 0 });
  const [timeLeft, setTimeLeft] = useState(0); // Time left for the entire quiz (in seconds)
  const [questionTimeLeft, setQuestionTimeLeft] = useState(60); // Time left for each question (1 minute)
  const [answeredQuestions, setAnsweredQuestions] = useState(0); // Count of answered questions
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `https://1c71-106-219-181-170.ngrok-free.app/quiz/questions/?subtype=${subtype}`,
          {
            headers: {
              Accept: 'application/json',
              'ngrok-skip-browser-warning': '98547',
            },
          }
        );
        setQuestions(response.data || []);
        setTimeLeft(response.data.length * 60); // Set total time based on number of questions (5 minutes if 5 questions)
      } catch (error) {
        setError('Failed to fetch questions. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (subtype) {
      fetchQuestions();
    }
  }, [subtype]);

  // Timer Countdown for each question
  useEffect(() => {
    const questionTimer = setInterval(() => {
      setQuestionTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(questionTimer);
          // Move to the next question when time for this one is up
          setSelectedQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questions.length - 1));
          return 60; // Reset timer for the next question
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(questionTimer); // Cleanup interval on unmount
  }, [selectedQuestionIndex]);

  // Timer Countdown for the entire quiz (submit after all questions are answered or time is up)
  useEffect(() => {
    const quizTimer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(quizTimer);
          handleSubmit(); // Submit the quiz when time is over
        }
        return Math.max(prevTime - 1, 0);
      });
    }, 1000);

    return () => clearInterval(quizTimer); // Cleanup interval on unmount
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [selectedQuestionIndex]: option };
      // Increment answered questions only if this question hasn't been answered yet
      if (!prevAnswers[selectedQuestionIndex]) {
        setAnsweredQuestions((prevCount) => prevCount + 0.5); // Increment only once per question
      }
      return newAnswers;
    });
  };

  const handleSubmit = () => {
    let attended = 0;
    let correct = 0;
    let wrong = 0;

    questions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];
      const correctAnswer = String(question.correct_answer).trim();

      if (selectedAnswer !== undefined) {
        attended++;
        if (String(selectedAnswer).trim() === correctAnswer) {
          correct++;
        } else {
          wrong++;
        }
      }
    });

    setResult({ attended, correct, wrong });
    setShowModal(true);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (loading)
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <p>Loading Questions...</p>
      </div>
    );

  if (error)
    return (
      <div style={{ textAlign: 'center', color: 'red', marginTop: '50px' }}>
        <p>{error}</p>
      </div>
    );

  if (!loading && questions.length === 0)
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <p>No questions available for this category. Please try another category.</p>
      </div>
    );

  return (

    <>

    <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          />
   
    <Container
  style={{
    maxWidth: '1500px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    margin: '0 auto',
    padding: '20px',
    background: 'linear-gradient(to right, #e9f8e0,rgb(7, 87, 87))',
    display: 'flex',  // Add flexbox to center the content
    flexDirection: 'column',
    justifyContent: 'center',  // Center content vertically
    alignItems: 'center',  // Center content horizontally
  }}
>
  {/* Outer Card */}
 {/* Outer Card */}
 <Card
    style={{
      padding: '20px',
      margin: '30px',
      borderRadius: '15px',
      background: 'linear-gradient(to right, #e9f8e0, #cfe8d9)', // Adjust colors for the gradient
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Subtle shadow
      border: 'none', // Removes default border
      width: '700px', // Fixed width
      display: 'flex',  // Ensure the inner card content is centered
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height:'70px'
    }}
  >
    <Card.Body>
      {/* Header Section */}
    
<div
  style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  }}
>
  <div style={{ fontSize: '1.2rem', color: '#555', display: 'flex', alignItems: 'center' }}>
    <FaClock style={{ marginRight: '8px', color: '#3e8740' }} /> {/* Timer icon */}
    <strong>{formatTime(timeLeft)}</strong>
  </div>
        <div style={{ width: '80px' }}>
          <CircularProgressbar
            value={(answeredQuestions / questions.length) * 100 || 0}
            text={`${answeredQuestions}/${questions.length}`}
            styles={buildStyles({
              pathColor: '#28a745',
              textColor: '#28a745',
              trailColor: '#f4f4f4',
            })}
          />
        </div>
      </div>

      {/* Question Selector (Round Buttons) */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        {questions.map((_, index) => (
          <Button
            key={index}
            onClick={() => setSelectedQuestionIndex(index)}
            style={{
              margin: '0 5px',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              padding: '0',
              backgroundColor: selectedQuestionIndex === index ? '#3e8740' : '#6c757d',
              color: 'white',
              border: 'none',
              fontSize: '16px',
              lineHeight: '40px',
            }}
            variant='success'
          >
            {index + 1}
          </Button>
        ))}
      </div>

      {/* Question Card */}
      <Card style={{ marginBottom: '20px', textAlign: 'left' }}>
        <Card.Body>
          <Card.Title style={{ fontWeight: 'bold' }}>
            {`Q${selectedQuestionIndex + 1}: ${questions[selectedQuestionIndex].question}`}
          </Card.Title>
          <ListGroup>
            {/* Iterate through the options in pairs */}
            {Array.from({ length: Math.ceil(questions[selectedQuestionIndex].options.length / 2) }).map(
              (_, rowIndex) => {
                const optionsPair = questions[selectedQuestionIndex].options.slice(
                  rowIndex * 2,
                  rowIndex * 2 + 2
                );

                return (
                  <div
                    key={rowIndex}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px', // Space between rows
                    }}
                  >
                    {/* Map over the pair of options for each row */}
                    {optionsPair.map((option, index) => (
                      <ListGroup.Item
                        key={index}
                        onClick={() => handleOptionSelect(option)}
                        style={{
                          cursor: 'pointer',
                          padding: '15px 20px',
                          flex: '0 0 48%', // Each button takes up 48% of the row
                          borderRadius: '30px', // Rounded corners
                          backgroundColor:
                            selectedAnswers[selectedQuestionIndex] === option ? '#3e8740' : '#fff',
                          color: selectedAnswers[selectedQuestionIndex] === option ? '#fff' : '#000',
                          border: '1px solid #3e8740', // Subtle border
                          boxShadow:
                            selectedAnswers[selectedQuestionIndex] === option
                              ? '0 4px 10px #3e8740' // Glow effect when selected
                              : '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow
                          transition: 'all 0.3s ease-in-out', // Smooth hover and selection effects
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor =
                            selectedAnswers[selectedQuestionIndex] === option
                              ? '#3e8740' // Darker blue on hover for selected option
                              : '#f8f9fa'; // Light grey on hover for unselected option
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor =
                            selectedAnswers[selectedQuestionIndex] === option
                              ? '#3e8740'
                              : '#fff';
                        }}
                      >
                        <strong style={{ marginRight: '10px' }}>
                          {String.fromCharCode(65 + rowIndex * 2 + index)}
                        </strong>
                        {option}
                      </ListGroup.Item>
                    ))}
                  </div>
                );
              }
            )}
          </ListGroup>
        </Card.Body>
      </Card>

      {/* Navigation Buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button
          onClick={() =>
            setSelectedQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0))
          }
          disabled={selectedQuestionIndex === 0}
          variant="secondary"
        >
          Previous
        </Button>
        {selectedQuestionIndex < questions.length - 1 ? (
          <Button
            onClick={() =>
              setSelectedQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questions.length - 1))
            }
           variant="success"
          >
            Next
          </Button>
        ) : (
          <Button onClick={handleSubmit} variant="success" color='rgb(7, 87, 87)'>
            Submit
          </Button>
        )}
      </div>
    </Card.Body>
  </Card>

  {/* Result Modal */}
  <Modal show={showModal} onHide={() => setShowModal(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>Quiz Results</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Total Questions: {questions.length}</p>
      <p>Questions Attended: {result.attended}</p>
      {/* <p>Correct Answers: {result.correct}</p>
      <p>Wrong Answers: {result.wrong}</p> */}
      <p><b>successfully Submitted...</b></p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="success" onClick={() => navigate('/TestPage')}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
</Container>
</>

  );
};

export default McqTestPage;
