import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,   } from 'react-router-dom';
import './index.css';
import Navbar from'./Navbar.js';
import Banner from'./Banner.js';
import LogoSection from './LogoSection.js';
import UserReview from './UserReview.js';
import Enquiry from './Enquiry.js';
import Footer from './Footer.js';
import SignUp from './SignUp.js';
import LoginPage from './LoginPage.js';
import Test from './Test.js';
import McqTestPage from './McqTestPage.js';
import QuestionPage from './QuestionPage'; 
import ProgrammingTestPage from './ProgrammingTestPage.js';
import CourseJava from './CourseJava.js';
import CoursePython from './CoursePython.js';
import CourseC from './CourseC.js';
import Dashboard from './Dashboard.js';
import Courses from './courses.js';
import Userdashboard from './Userdashboard.js';
import Admindashboardg from './Admindashboardg.js'
import UploadQuestions from './Uploadquestions.js';
import TestPage from './Testpage.js';
import Cwithus from "./chatwithus";
import Company from "./Company.js";
import Enquire from "./Enquire.js";
import Frontcourse from "./Frontcourse";


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [userRole, setUserRole] = useState('');

  // Check for logged-in user on page load
  useEffect(() => {
    const storedUser = localStorage.getItem('username');
    const storedRole = localStorage.getItem('role');
    
    if (storedUser && storedRole) {
      setIsLoggedIn(true);
      setUsername(storedUser);
      setUserRole(storedRole);
      // Fetch user role from API
      fetch(`https://1c71-106-219-181-170.ngrok-free.app/quiz/users/create/user/`)
        .then(response => response.json())
        .then(data => {
          const user = data.find(user => user.username === storedUser);
          if (user) setUserRole(user.role);
        })
        .catch(error => console.error('Error fetching user data:', error));
    }
  }, []);

  // Handle automatic logout on root path
  useEffect(() => {
    if (location.pathname === '/') handleLogout();
  }, [location]);

  // Handle logout logic
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setUserRole('');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
  };


  return (
    <Router>
      {/* <Navbar 
        isLoggedIn={isLoggedIn} 
        username={username} 
        userRole={userRole} 
        handleLogout={handleLogout} 
      /> */}


      <Routes>
        {/* <Route path="/Navbar" element={<Navbar />} /> */}
        <Route path="/LoginPage" element={<LoginPage  isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}  setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setUserRole={setUserRole} />} />
        <Route path="/SignUp" element={<SignUp setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setUserRole={setUserRole} />} />
        <Route path="/Test" element={<Test isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} />
        <Route path="/McqTestPage" element={<McqTestPage isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}  />} />
        <Route path="/QuestionPage" element={<QuestionPage isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} />
        <Route path="/ProgrammingTestPage" element={<ProgrammingTestPage isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} />
        <Route path="/CourseJava" element={<CourseJava isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}  />} />
        <Route path="/CoursePython" element={<CoursePython isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}  />} />
        <Route path="/CourseC" element={<CourseC isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} />
        <Route path="/CourseJava" element={<CourseJava isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} /> 
        <Route path="/CoursePython" element={<CoursePython isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} /> 
        <Route path="/CourseC" element={<CourseC isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />} /> 
        <Route path="/Dashboard" element={<Dashboard isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />}/>
        <Route path="/courses" element={<Courses isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} />}/>
        <Route path="/Userdashboard" element={<Userdashboard isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}/>} />
        <Route path="/chatwithus" element={<Cwithus/>} />
        <Route path="/Frontcourse" element={<Frontcourse/>} />
        <Route path="/Admindashboardg" element={<Admindashboardg isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}  />} />
        <Route path="/TestPage" element={<TestPage isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}/>} />
        <Route path="/Uploadquestions" element={<UploadQuestions isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}/>} />
        <Route path="/Company" element={<Company isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}/>} />
        <Route path="/Enquire" element={<Enquire isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout}/>} />

        <Route path="/" element={
            <>
      <Navbar 
              isLoggedIn={isLoggedIn} 
              username={username} 
              userRole={userRole} 
              handleLogout={handleLogout} 
            />
            <Banner/>
            <LogoSection/>
            <Frontcourse/>
            <UserReview/>
            <Enquiry/>
            <Footer/>
            <Cwithus/>
            </>
          } />
        </Routes>
    </Router>
  );
}

export default App;