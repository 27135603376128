import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { FaCheckCircle, FaQuestionCircle, FaClipboardList } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, LineController, Title, Tooltip, Legend } from 'chart.js';
import Navbar from './Navbar'

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, LineController, Title, Tooltip, Legend);

const Dashboard = ({ isLoggedIn ,userRole, handleLogout, username }) => {
  
  const navigate = useNavigate();
  const questionsChartRef = useRef(null);
  const tasksChartRef = useRef(null);
  const [showAssessmentCard, setShowAssessmentCard] = useState(false);
  const [assessmentType, setAssessmentType] = useState('pending');
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  

  const companyData = [
    { name: 'Company A', about: 'A leading tech company in AI and ML.', location: 'San Francisco, CA' },
    { name: 'Company B', about: 'Innovators in blockchain technology.', location: 'New York, NY' },
    { name: 'Company C', about: 'Pioneers in cloud computing and storage.', location: 'Seattle, WA' },
  ];

  // Modal state
  const handleStartTask = () => {
    navigate('/Test');
  };

  const handleYourCourses = () => {
    navigate('/courses');
  };

  const handleToggleAssessmentCard = () => {
    setShowAssessmentCard(prevState => !prevState);
  };

  const handleShowCompanyModal = () => {
    setShowCompanyModal(true); // Show the modal when "Company" button is clicked
  };

  const handleCloseCompanyModal = () => {
    setShowCompanyModal(false); // Close the modal
  };

  const handleAllocatedStudent = (companyName) => {
    // Define the logic for allocated student button click here.
    console.log(`Allocated student to ${companyName}`);
  };
  useEffect(() => {
    const questionsCtx = document.getElementById('questionsSolvedChart').getContext('2d');
    const tasksCtx = document.getElementById('tasksCompletedChart').getContext('2d');

    if (questionsChartRef.current) {
      questionsChartRef.current.destroy();
    }

    questionsChartRef.current = new Chart(questionsCtx, {
      type: 'line',
      data: {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [{
          label: 'Questions Solved',
          data: [12, 19, 3, 5, 2, 3, 7],
          fill: true,
          backgroundColor: 'rgba(101, 92, 242, 0.2)',
          borderColor: '#655cf2',
          tension: 0.1,
          borderWidth: 2,
        }],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    if (tasksChartRef.current) {
      tasksChartRef.current.destroy();
    }

    tasksChartRef.current = new Chart(tasksCtx, {
      type: 'line',
      data: {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [{
          label: 'Tasks Completed',
          data: [5, 8, 15, 3, 12, 6, 9],
          fill: true,
          backgroundColor: 'rgba(252, 186, 3, 0.2)',
          borderColor: '#fcba03',
          tension: 0.1,
          borderWidth: 2,
        }],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      if (questionsChartRef.current) {
        questionsChartRef.current.destroy();
      }
      if (tasksChartRef.current) {
        tasksChartRef.current.destroy();
      }
    };
  }, []);

  const renderAssessmentCards = () => {
    const cardsData = assessmentType === 'pending' ? ['Assessment 1', 'Assessment 2', 'Assessment 3'] : ['Assessment 1', 'Assessment 2', 'Assessment 3'];

    return cardsData.map((title, index) => {
      const imageUrl = assessmentType === 'pending' ? 'https://www.taxscan.in/wp-content/uploads/2017/01/Re-Assessment-Taxscan.jpg' : 'https://media.istockphoto.com/id/1490683819/photo/mission-achievement-strategic-planning-and-success-in-business-completing-the-task-and.jpg?s=612x612&w=0&k=20&c=SZEJ82DtCwHxxLGBMNBRF6EkGx78vnqNNH94KplV22c=';

      return (
        <Col md={4} key={index}>
          <Card style={{ textAlign: 'center', padding: '20px', marginTop: '20px', borderRadius: '8px' }}>
            <Card.Img variant="top" src={imageUrl} alt="Assessment Image" style={{ borderRadius: '8px', marginBottom: '15px' }} />
            <Card.Body>
              <Card.Title style={{ color: '#333' }}>{title}</Card.Title>
              <Card.Text style={{ color: '#777' }}>
                {assessmentType === 'pending' ? 'This assessment is under review' : 'Average score: 70%'}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      );
    });
  };

  const renderStatCard = (icon, count, text) => (
    <Col md={3}>
      <Card style={{ textAlign: 'center', padding: '20px', borderRadius: '8px' }}>
        <Card.Body>
          {icon}
          <Card.Title style={{ fontSize: '24px', color: '#655cf2' }}>{count}</Card.Title>
          <Card.Text style={{ color: '#777' }}>{text}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <>

    <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          />
    <Container fluid style={{ padding: '20px' }}>
      <Row style={{ marginBottom: '20px' }}>
        <Col>
          <Card style={{ backgroundColor: '#f7f9fc', padding: '20px', borderRadius: '8px' }}>
            <Card.Body>
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ color: '#333' }}>Hi <span style={{ color: '#655cf2' }}>{username}</span>, Welcome</h2>
                <p style={{ color: '#555' }}>Let's Prepare, Practice, and Perform!</p>
                <Button variant="warning" onClick={handleStartTask} style={{ marginRight: '10px' }}>Start Task</Button>
                <Button variant="warning" onClick={handleYourCourses} style={{ marginRight: '10px' }}>Your Course</Button>
                <Button variant="warning" onClick={handleToggleAssessmentCard} style={{ marginRight: '10px' }}>Your Assessments</Button>
                <Button variant="warning" onClick={handleShowCompanyModal}>Company</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginBottom: '20px' }}>
        {renderStatCard(<FaQuestionCircle size={40} />, 0, 'Questions Solved')}
        {renderStatCard(<FaCheckCircle size={40} />, 3, 'Practice Package Enrolled')}
        {renderStatCard(<FaClipboardList size={40} />, 0, 'Assessment Pending')}
        {renderStatCard(<FaClipboardList size={40} />, '39.33%', 'Avg Assessment Marks')}
      </Row>

      {showAssessmentCard && (
        <Row style={{ marginBottom: '20px', textAlign: 'center' }}>
          <Col md={12}>
            <Card style={{ textAlign: 'center', padding: '10px', borderRadius: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                <Button
                  variant="info"
                  onClick={() => setAssessmentType('pending')}
                  style={{ backgroundColor: assessmentType === 'pending' ? '#5bc0de' : '#fff', color: assessmentType === 'pending' ? '#fff' : '#333' }}
                >
                  Pending Assessments
                </Button>
                <Button
                  variant="success"
                  onClick={() => setAssessmentType('completed')}
                  style={{ backgroundColor: assessmentType === 'completed' ? '#5cb85c' : '#fff', color: assessmentType === 'completed' ? '#fff' : '#333' }}
                >
                  Completed Assessments
                </Button>
              </div>
              <Row>{renderAssessmentCards()}</Row>
            </Card>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: '40px' }}>
        <Col md={6}>
          <Card style={{ padding: '20px', borderRadius: '8px' }}>
            <Card.Body>
              <h4>Questions Solved</h4>
              <canvas id="questionsSolvedChart"></canvas>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ padding: '20px', borderRadius: '8px' }}>
            <Card.Body>
              <h4>Tasks Completed</h4>
              <canvas id="tasksCompletedChart"></canvas>
            </Card.Body>
          </Card>
        </Col>
      </Row>

  
    {/* Company Modal */}
    <Modal show={showCompanyModal} onHide={handleCloseCompanyModal}>
        <Modal.Header closeButton>
          <Modal.Title>Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {companyData.map((company, index) => (
            <Card key={index} style={{ marginBottom: '15px', padding: '10px', borderRadius: '8px' }}>
              <Card.Body>
                <Card.Title>{company.name}</Card.Title>
                <Card.Text>{company.about}</Card.Text>
                <Card.Text>
                  <strong>Location:</strong> {company.location}
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() => handleAllocatedStudent(company.name)}
                >
                  Allocated Student
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCompanyModal}>Close</Button>
        </Modal.Footer>
      </Modal>

    </Container>
    </>
  );
};

export default Dashboard;
